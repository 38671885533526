import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import pic from "../../Assets/mypic.jpg";
import "./AboutCard.css";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Muhammed Fariz KP </span>
             from <span className="purple"> Kerala, India.</span>
            <br /> I am a 4th year student pursuing a B.tech in Information Technology
            <br/>
            <br/>
            <li>
            <img src={pic} className="rounded-img" alt="avatar"/>
            </li>
            <br/>
            <br/>
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
            
          </ul>

        
         
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
